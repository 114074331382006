import ZoomVideo from "@zoom/videosdk";

export const connectToRoom = async (
  accessToken,
  setRoom,
  setErrorMessage,
  dispatch,
) => {
  try {
    const zoomVideo = ZoomVideo.createClient();

    const base64Url = accessToken.split(".")[1];
    const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    const payload = JSON.parse(atob(base64));

    const navegadorUsuarioSoportaAudio =
      ZoomVideo.checkSystemRequirements().audio;

    if (!navegadorUsuarioSoportaAudio) {
      dispatch(setErrorMessage("400", "Su navegador no soporta audio."));
      return;
    }

    // Para mas información sobre las opciones de init, ver:
    // https://marketplacefront.zoom.us/sdk/custom/web/modules/VideoClient.html#init
    await zoomVideo
      .init("en-US", "CDN", {
        patchJsMedia: true,
        enforceMultipleVideos: true,
        stayAwake: true,
      })
      .then(async (res) => {
        await zoomVideo
          .join(payload.tpc, accessToken, payload.user_identity)
          .then((res) => {
            setRoom(zoomVideo);
          });
      });
  } catch (err) {
    dispatch(
      setErrorMessage(
        "400",
        "No se ha detectado algún micrófono. Verifique su conexión e intente nuevamente",
      ),
    );
  }
};

export function isShallowEqual(objA, objB) {
  if (objA === objB) {
    return true;
  }

  if (!objA || !objB) {
    return false;
  }

  const aKeys = Object.keys(objA);
  const bKeys = Object.keys(objB);
  const len = aKeys.length;

  if (bKeys.length !== len) {
    return false;
  }

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < len; i++) {
    const key = aKeys[i];

    if (objA[key] !== objB[key] || !Object.hasOwn(objB, key)) {
      return false;
    }
  }

  return true;
}

export function isArrayShallowEqual(arrayA, arrayB) {
  const len = arrayA.length;

  if (arrayB.length !== len) {
    return false;
  }

  for (let i = 0; i < len; i++) {
    if (!isShallowEqual(arrayA[i], arrayB[i])) {
      return false;
    }
  }

  return true;
}
