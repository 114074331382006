import {
  MenuItem,
  Popover,
  Tooltip,
  Typography,
  makeStyles,
} from "@material-ui/core";
import LogoutIcon from "@mui/icons-material/Logout";
import React, { useState } from "react";
import CallButton from "../../../../commons/callButton/CallButton";

const useStyles = makeStyles({
  menu: {
    height: 320,
    "& .MuiPaper-root": {
      width: "fit-content", // Ajusta el ancho del menú
      maxHeight: 120, // Ajusta la altura máxima del menú si hay muchas opciones
      overflowY: "auto", // Habilita el desplazamiento vertical si el menú es más alto que el máximo
    },
  },
  popoverItem: {
    height: 40,
    border: "1px solid #f0e9e9",
  },
});

const LeaveRoomButton = ({ room }) => {
  const classes = useStyles();

  // La API de material-ui para <Popover> requiere de un
  // elemento de anchorEl. Lo usamos tanto para abrir el
  // popover como para su posición de renderizado.
  const [anchorEl, setAnchorEl] = useState(null);

  const esHost = room?.isHost();
  const esProfesional =
    room?.getCurrentUserInfo()?.userIdentity === "Profesional";
  const puedeFinalizarReunion = esHost && esProfesional;

  const handleFinalizarReunion = async () => {
    room.leave(true).then((res) => {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    });
  };

  const handleSalirReunion = async () => {
    room.leave().then((res) => {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {puedeFinalizarReunion ? (
        <div>
          <CallButton
            src={<LogoutIcon />}
            usarIconoEnBlanco={true}
            action={Boolean(anchorEl) ? handleFinalizarReunion : handleClick}
          />
          <Popover
            className={classes.popoverList}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "center",
              horizontal: "left",
            }}>
            <MenuItem
              onClick={handleSalirReunion}
              className={classes.popoverItem}>
              <Typography>Salir de la reunión</Typography>
            </MenuItem>
            <Tooltip
              title="Todos los participantes saldrán!"
              dir="bottom"
              placement="bottom"
              arrow>
              <MenuItem
                onClick={handleFinalizarReunion}
                className={classes.popoverItem}>
                <Typography>Terminar reunión</Typography>
              </MenuItem>
            </Tooltip>
          </Popover>
        </div>
      ) : (
        <CallButton
          src={<LogoutIcon />}
          action={handleSalirReunion}
          usarIconoEnBlanco={true}
        />
      )}
    </>
  );
};

export default LeaveRoomButton;
