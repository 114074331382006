const config = {
  //local
  // baseURL: "https://localhost:7097/api/",
  //azure
  //baseURL: "https://srvdev.accessinformatica.com.ar:1190/TelemedicinaApi/api/",
  //test
  baseURL: "https://www.mlconectividad.com.ar:9720/zoom/api/",
  //prod
  //baseURL: "https://www.mlconectividad.com.ar:9710/api/",
  apiKey: "AJAd834Ojd123",
};
export default config;
