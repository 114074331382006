import { makeStyles, useMediaQuery } from "@material-ui/core";
import { useContext, useEffect, useRef, useState } from "react";
import logo from "../../../../../assets/images/marcaAccess.png";
import { MIN_CELL_WIDTH } from "../../../../../utils/layout";
import WaitingRoom from "../../WaitingRoom";
import MediaControlsContainer from "../../buttons/MediaControlsContainer";
import { RoomContext } from "../context/roomContext";
import { useCanvasDimension } from "../hooks/useCanvasDimension";
import { useGalleryLayout } from "../hooks/useGalleryLayout";
import useHeight from "../hooks/useHeight";
import Avatar from "./Avatar";
import SelfViewContainer from "./SelfViewContainer";
import ShareView from "./ShareView";

const useStyles = makeStyles((theme) => ({
  salaEsperaContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    padding: "10px 30px",
    height: "100%",
    background: `linear-gradient(to right, ${theme.palette.primary.homeGradient1}, ${theme.palette.primary.homeGradient2})`,
    width: "100%",
    overflow: "hidden",
    borderBottom: `1px solid ${theme.palette.primary.grey}`,
    "& a": {
      textDecoration: "none",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
      padding: "20px",
    },
  },
  enLlamadaContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: `linear-gradient(to right, ${theme.palette.primary.homeGradient1}, ${theme.palette.primary.homeGradient2})`,
    overflow: "hidden",
    borderBottom: `1px solid ${theme.palette.primary.grey}`,
    "& a": {
      textDecoration: "none",
    },

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  viewport: {
    position: "relative",
    display: "flex",
    height: "100%",
    width: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    // gap: 1vw, para que haya espacio entre
    // el selfview y el preview
    gap: "1vw",
    backgroundColor: (prop) =>
      prop.deberiaEstarEnSalaEspera ? "transparent" : "rgb(0, 0, 0)",

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      padding: 5,
    },
  },
  viewportMobileReceiveSharing: {
    display: "grid",
    gridTemplateRows: "1fr 1fr",
    position: "relative",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    boxSizing: "border-box",
    // gap: 1vw, para que haya espacio entre
    // el selfview y el preview
    gap: "1vw",
    backgroundColor: "rgb(0, 0, 0)",
    justifyContent: "center",
    padding: 5,
  },
  videoContainer: {
    display: "flex",
    position: "relative",
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  videoCanvas: {
    width: "100%",
    height: "100%",
    backgroundColor: "transparent",
    position: "absolute",
    zIndex: 6,
  },
  selfVideoNonSab: {
    position: "absolute",
    display: "none",
    zIndex: 7,
  },
  avatarList: {
    position: "absolute",
    width: "100%",
    height: "100%",
    pointerEvents: "none",
    top: 0,
    left: 0,
    padding: 0,
    margin: 0,
  },
  videoContainerIsSharing: {
    // este width debe ser el mismo que
    // MIN_CELL_WIDTH de layout.js
    width: `${MIN_CELL_WIDTH}px`,
    flexShrink: 0,

    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  logo: {
    maxHeight: 100,
    maxWidth: 350,
    borderBottom: `2px solid ${theme.palette.primary.homeGradient1}`,
    marginTop: 20,
  },
}));

const ZoomParticipantContainer = () => {
  const zmClient = useContext(RoomContext);

  const mediaStream = zmClient?.getMediaStream();
  const SELF_VIDEO_ID = "self-view-video";
  const isVideoDecodeReady = true;

  const [isRecieveSharing, setIsRecieveSharing] = useState(false);

  const videoRef = useRef(null);
  const shareViewRef = useRef({ selfShareRef: null });

  // Que la altura del contenedor principal sea la altura del viewport.
  // En algunos navegadores mobile, 'height: 100vh' iguala la altura de la pantalla,
  // y no la del viewport. Esto se ve mal cuando la barra superior del navegador
  // se encuentra abierta. Vamos a setear la altura dinámicamente con 'window.innerHeight'.
  // Consecuentemente, esto se va a ver bien en navegadores mobile sin importar si
  // la barra de URL está abierta o no.
  const height = useHeight();

  const canvasDimension = useCanvasDimension(
    mediaStream,
    videoRef,
    isRecieveSharing,
  );

  const participanteLocal = zmClient?.getCurrentUserInfo();
  const haFinalizadoSesion = !zmClient.getSessionInfo()?.isInMeeting;

  const esPaciente = participanteLocal?.userIdentity === "Paciente";
  const noIngresoProfesional = zmClient
    .getAllUser()
    .every((user) => user.userIdentity !== "Profesional");
  const deberiaEstarEnSalaEspera = esPaciente && noIngresoProfesional;

  const classes = useStyles({
    deberiaEstarEnSalaEspera,
  });

  const { visibleParticipants, layout: videoLayout } = useGalleryLayout(
    zmClient,
    mediaStream,
    isVideoDecodeReady,
    videoRef,
    canvasDimension,
    deberiaEstarEnSalaEspera,
  );

  const tieneViewportPocaAltura = window.innerHeight < 401;
  const hayParticipantesRemotos =
    visibleParticipants.filter((u) => u.userId !== participanteLocal?.userId)
      .length >= 1;
  const esLayoutMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  // Tenemos 2 situaciones especiales:
  // 1. Siguiendo el ejemplo de MLNet, cuando el iframe de la llamada es
  // demasiado corto en altura, se debe dejar de renderizar al participante
  // local y solamente dejar el remoto.
  // 2. Algunos dispositivos mobiles son tan pequeños que las
  // camaras de dos participantes + el share-view no entran.
  // Por eso escondemos la camara del participante local.
  const noRenderizarParticipanteLocal =
    (tieneViewportPocaAltura && hayParticipantesRemotos) ||
    (esLayoutMobile && isRecieveSharing);

  const currentUserIndex = visibleParticipants.findIndex(
    (user) => user.userId === participanteLocal?.userId,
  );
  let selfVideoLayout = null;

  if (currentUserIndex > -1) {
    const item = videoLayout[currentUserIndex];
    if (item && canvasDimension) {
      selfVideoLayout = {
        ...item,
      };
    }
  }

  useEffect(() => {
    window.addEventListener("beforeunload", () => zmClient.leave());
    return () => {
      zmClient.leave();
    };
  }, [zmClient]);

  useEffect(() => {
    if (haFinalizadoSesion) {
      const siteUrl = window.location.origin;
      window.location.href = siteUrl;
    }
  }, [zmClient, haFinalizadoSesion]);

  return (
    <div
      className={
        deberiaEstarEnSalaEspera
          ? classes.salaEsperaContainer
          : classes.enLlamadaContainer
      }
      style={{ height }}>
      <div
        className={
          esLayoutMobile && isRecieveSharing
            ? classes.viewportMobileReceiveSharing
            : classes.viewport
        }>
        <ShareView
          ref={shareViewRef}
          onRecieveSharingChange={setIsRecieveSharing}
        />
        <div
          className={`${classes.videoContainer} ${
            isRecieveSharing ? classes.videoContainerIsSharing : ""
          }`}>
          {/* el video-canvas es donde se van a renderizar todos */}
          {/* los videos de los demas participantes */}
          <canvas
            className={classes.videoCanvas}
            id="video-canvas"
            ref={videoRef}
          />
          {deberiaEstarEnSalaEspera && (
            <img
              src={logo}
              className={classes.logo}
              alt="Logo AccessInformática"
            />
          )}
          <SelfViewContainer
            id={SELF_VIDEO_ID}
            className={classes.selfVideoNonSab}
            debeUsarEtiquetaVideo={mediaStream?.isRenderSelfViewWithVideoElement()}
            selfVideoLayout={selfVideoLayout}
            style={
              selfVideoLayout
                ? {
                    display: noRenderizarParticipanteLocal ? "none" : "block",
                    width: `${selfVideoLayout.width}px`,
                    height: `${selfVideoLayout.height}px`,
                    top:
                      esLayoutMobile && deberiaEstarEnSalaEspera
                        ? `${selfVideoLayout.y + 70}px`
                        : `${selfVideoLayout.y}px`,
                    left: `${selfVideoLayout.x}px`,
                    pointerEvents: "none",
                  }
                : undefined
            }
          />
          {!isRecieveSharing && (
            <ul className={classes.avatarList}>
              {visibleParticipants.map((user, index) => {
                if (index > videoLayout.length - 1) {
                  return null;
                }

                // como estamos iterando sobre todos los usuarios, queremos
                // saber si justo este es el participante local para
                // no renderizarlo
                const esAvatarParticipanteLocal =
                  participanteLocal?.userId === user.userId;
                if (
                  noRenderizarParticipanteLocal &&
                  esAvatarParticipanteLocal
                ) {
                  return null;
                }

                const dimension = videoLayout[index];
                const { width, height, x, y } = dimension;
                const posicionTop =
                  esLayoutMobile && deberiaEstarEnSalaEspera
                    ? `${y + 70}px`
                    : `${y}px`;

                return (
                  <Avatar
                    participant={user}
                    style={{
                      width: `${width}px`,
                      height: `${height}px`,
                      top: posicionTop,
                      left: `${x}px`,
                    }}
                    tieneViewportPocaAltura={tieneViewportPocaAltura}
                    esParticipanteLocal={
                      participanteLocal?.userId === user.userId
                    }
                    key={user.userId}
                  />
                );
              })}
            </ul>
          )}
        </div>
        {deberiaEstarEnSalaEspera && <WaitingRoom />}
      </div>
      <MediaControlsContainer
        room={zmClient}
        selfShareCanvas={shareViewRef.current?.selfShareRef}
        deberiaEstarEnSalaEspera={deberiaEstarEnSalaEspera}
      />
    </div>
  );
};

export default ZoomParticipantContainer;
